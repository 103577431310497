import { Link, useIntl } from "gatsby-plugin-react-intl";
import React, { useState, useEffect } from "react";
import request from "graphql-request";
import "../assets/css/singleRealizationCard.scss";

function SingleRealizationCard({ title, img, to, bg }) {
  const [buttons, setButtons] = useState({});
  const strapiHost = process.env.GATSBY_API_URL;
  const locale = useIntl();

  useEffect(() => {
    const fetch = async () => {
      const res = await request(
        process.env.GATSBY_GRAPHQL_URL,
        `{
          button(locale: "${locale.locale}"){
            seeThisRealization
          }
        }`
      );
      return res;
    };
    fetch().then((items) => {
      setButtons(items.button);
    });
  }, []);

  return (
    <Link
      to={`/realizations/${to}`}
      className="project__single-realization"
      style={{ ...(bg ? { background: bg } : {}) }}
    >
      <img src={`${strapiHost}${img}`} />
      <h4>{title}</h4>
      <button className="btn btn-primary">{buttons.seeThisRealization}</button>
    </Link>
  );
}
export default SingleRealizationCard;
