import { Link } from "gatsby-plugin-react-intl";
import request from "graphql-request";
import React, { useEffect, useState } from "react";
import "../assets/css/realizations.scss";
import "../assets/css/shops.scss";
import ContactUs from "../components/contactUs";
import Head from "../components/head";
import Layout from "../components/layout";
import Loading from "../components/loading";
import ShowProvider from "../components/showProvider";
import SingleRealizationCard from "../components/singleRealizationCard";

function ShopsPage({ pageContext }) {
  // eslint-disable-next-line
  const [data, setData] = useState({});
  const [buttons, setButtons] = useState({});
  const [realizations, setRealizations] = useState([]);
  const [loading, setLoading] = useState(true);
  const strapiHost = process.env.GATSBY_API_URL;

  useEffect(() => {
    const fetch = async () => {
      const res = await request(
        process.env.GATSBY_GRAPHQL_URL,
        `{
          shop(locale: "${pageContext.intl.language}") {
            pageTitle
            description
            description_presta
            img {
              url
            }
            img_presta {
              url
            }
            title 
            title_presta
            title_realizations
            shops_realizations {
              img {
                url
              }
              title
            }
            shops_single {
              description
              img {
                url
              }
              title
            }
          }
          button(locale: "${pageContext.intl.language}"){
            seeallrealizations
          }
          realizations(limit:3,locale: "${pageContext.intl.language}",where:{realization_category:{slug:"shops"}}){
            title
            logo{
              url
            }
            thumbnailOptionalLogo{
              url
            }
            slug
          }
        }`
      );
      return res;
    };
    fetch().then((items) => {
      setData(items.shop);
      setButtons(items.button);
      setRealizations(items.realizations);
      setLoading(false);
    });
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <ShowProvider>
          <Layout contactUs={false} initial={false} nav={false}>
            <div className="shops__section1">
              <div className="shops__section1-left">
                <img
                  src={`${strapiHost}${data.img.url}`}
                  className="shops__img"
                />
              </div>
              <div className="shops__section1-right">
                <h1 className="shops__section1-title">{data.title}</h1>
                <p className="shops__section1-description">
                  {data.description}
                </p>
              </div>
            </div>
          </Layout>
          <div className="shops__section2">
            <div className="shops__section2__single">
              <Layout nav={false} initial={false} contactUs={false}>
                <div className="shops__section2__single-wrapper">
                  {data.shops_single.map(({ description, img, title }, key) => (
                    <div className="shops__section2__single-offer" key={key}>
                      <div className="shops__section2__single-header">
                        <img
                          src={`${strapiHost}${img.url}`}
                          className="shops__section2__single-img2"
                        />
                        <h2 className="shops__section2__single-title">
                          {title}
                        </h2>
                      </div>
                      <p className="shops__section2__single-desc1">
                        {description}
                      </p>
                    </div>
                  ))}
                </div>
              </Layout>
            </div>
          </div>
          <Layout nav={false} initial={false} contactUs={false}>
            <div className="shops__section3">
              <div className="shops__section3-left">
                <img
                  src={`${strapiHost}${data.img_presta.url}`}
                  className="shops__img-presta"
                />
              </div>
              <div className="shops__section3-right">
                <h2 className="shops__section3-title">{data.title_presta}</h2>
                <p className="shops__section3-description">
                  {data.description_presta}
                </p>
              </div>
            </div>
            <div className="shops__section4">
              <h2 className="shops__section4-title">
                {data.title_realizations}
              </h2>
              <div className="shops__section4-content">
                {realizations.map(
                  ({ logo, title, slug, thumbnailOptionalLogo }, key) => (
                    <SingleRealizationCard
                      key={key}
                      bg="#f8f9fc"
                      title={title}
                      img={
                        thumbnailOptionalLogo
                          ? thumbnailOptionalLogo.url
                          : logo.url
                      }
                      to={slug}
                    />
                  )
                )}
              </div>
              <div className="d-flex justify-content-center">
                <Link to="/realizations">
                  <button className="btn btn-success btn-lg " type="button">
                    {buttons.seeallrealizations}
                  </button>
                </Link>
              </div>
            </div>
          </Layout>
        </ShowProvider>
      )}
      <ContactUs />
    </>
  );
}

export default ShopsPage;
