import { Link, useIntl } from "gatsby-plugin-react-intl";
import request from "graphql-request";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import EmailIcon from "../images/email-icon.svg";

function ContactUs(p) {
  const [buttons, setButtons] = useState({});
  const [loading, setLoading] = useState(true);
  const intl = useIntl();

  useEffect(() => {
    const fetch = async () => {
      const res = await request(
        process.env.GATSBY_GRAPHQL_URL,
        `  {
          button(locale: "${intl.locale}") {
            askforfulloffer
          }
        }`
      );
      return res;
    };
    fetch().then((items) => {
      setButtons(items.button);
      setLoading(false);
    });
  }, []);
  return (
    !loading && (
      <Container>
        <Row>
          <Col
            lg={11}
            className="mx-auto d-flex justify-content-center align-items-center my-5"
          >
            <Link to="/contact">
              <button
                className="btn btn-primary btn-md "
                type="button"
                style={{
                  padding: "10px 20px",
                }}
              >
                <img src={EmailIcon} />
                {/* <Link to="/contact">{buttons.askforfulloffer}</Link> */}
                {buttons.askforfulloffer}
              </button>
            </Link>
          </Col>
        </Row>
      </Container>
    )
  );
}
export default ContactUs;
