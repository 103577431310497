import React, { useEffect, useState } from "react";
import { useIntl } from "gatsby-plugin-react-intl";
import { motion } from "framer-motion";
import request from "graphql-request";
import pImage from "../images/black-p.svg";
import Head from "./head";

function Loading({ litle }) {
  const [start, setStart] = useState(false);
  const [texts, setTexts] = useState({});
  const [long, setLong] = useState(false);
  const intl = useIntl();
  const fetch = async () => {
    const res = await request(
      process.env.GATSBY_GRAPHQL_URL,
      `{
        phrase(locale: "${intl.locale}") {
         loading
         longLoading
        }

      }`
    );
    return res;
  };
  useEffect(() => {
    fetch().then((e) => {
      setTexts(e.phrase);
    });

    const interval = setTimeout(() => {
      setStart(true);
    }, 1000);
    const keepLoad = setTimeout(() => {
      setLong(true);
    }, 5000);
    return () => {
      clearInterval(interval);
      clearInterval(keepLoad);
    };
  }, []);
  return (
    <div
      style={{
        height: litle ? "auto" : "80vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/*  */}
      {start ? (
        litle ? (
          <>{texts.loading}</>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              alignItems: "center",
            }}
          >
            <div style={{ position: "relative" }}>
              <img src={pImage} />
              <motion.span
                initial={{ rotateY: 0 }}
                animate={{ rotateY: 360 }}
                transition={{ duration: 2, repeat: Infinity }}
                style={{
                  width: "12px",
                  height: "12px",
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  background: "#2F55D4",
                  borderRadius: "30px",
                }}
              />
            </div>
            {long && <>{texts.longLoading}</>}
          </div>
        )
      ) : (
        <></>
      )}
    </div>
  );
}

export default Loading;
